.chat-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #fff;
}
/*
.message-display {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    max-width: 60%;
}

.sent {
    background-color: #4caf50;
    margin-left: auto;
}

.received {
    background-color: #2196f3;
    margin-right: auto;
}

.chat-input {
    padding: 10px;
    display: flex;
}

.chat-input input {
    flex-grow: 1;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
}

.chat-input button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #4caf50;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.chat-input button:hover {
    background-color: #45a049;
}
*/