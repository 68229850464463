/* LandingPageStyles.css */

.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);
    background-size: 400% 400%;
    animation: gradient 25s ease infinite;
}

.logo {
    color: black;
    /* Change logo elements in index.css h2 */
}

.content {
    font-size: 2em;
    text-align: center;
}

.value-proposition {
    font-size: 2em;
    text-align: center;
}

.get-started {
    background-color: black;
    color: white;
    padding: 0.5em 1em;
    border: 1px solid var(--primary-dark);
    border-radius: 0.25em;
    cursor: pointer;
    font-size: 1.25em;
    text-align: center;
    transition: background-color 0.3s;
}

.get-started:hover {
    background-color: white;
    color: black;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
};

@media screen and (max-width: 940px) {
    .landing-page {
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
    }

    .content {
        font-size: 1.5em;
    }

    .value-proposition {
        font-size: 1.5em;
    }

    .get-started {
        font-size: 1em;
    }
}