.find-match-root {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  width: 100vw;
  background-color: rgba(1, 1, 1, 0.2);
}

.find-match-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  border: 4px solid black;
  width: 40vw;
  height: auto;
  padding: 8px;
}

@media screen and (max-width: 940px) {
  .find-match-container {
    width: 90vw;
  }
}

.find-match-title {
  text-align: center;
  margin: 0.5rem;
}

.find-match-text {
  margin: 1rem;
}

.find-match-button {
  margin: 1rem;
}
