input[type="text"] {
  color: black;
}

.chat-input-suggestion-container {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
}

.chat-input-suggestion {
  padding: 8px;
  margin: 8px;
  background-color: #ccc;
  border-radius: 8px;
  cursor: pointer;
}

.chat-input-container {
  display: flex;
  flex-direction: column;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f3f3f3;
}

.chat-input input {
  flex: 1;
  padding: 8px 10px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.chat-input input:disabled {
  background-color: #c6c6c6;
  cursor: not-allowed;
}

.chat-input button {
  padding: 8px 12px;
  background-color: #4caf50;
  border: none;
  color: white;
  cursor: pointer;
}

.chat-input button:disabled {
  background-color: #2b2b2b;
  cursor: not-allowed;
}

.emoji-picker-icon {
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.emoji-picker {
  position: absolute;
  bottom: 50px;
  background: white;
  border: 1px solid #ccc;
  z-index: 2;
}

.generate-response {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  margin-right: 5px;
  cursor: pointer;
}
