/* LoginStyles.css */

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);
    background-size: cover;
  animation: gradient 25s ease infinite;
}

.brand {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.brand h1 {
  color: white;
  text-transform: uppercase;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #00000076;
  border-radius: 2rem;
  padding: 3rem 5rem;
}

.user,
.pass {
  background-color: transparent;
  padding: 1rem;
  border: 0.1rem solid #4e0eff;
  border-radius: 0.4rem;
  color: white;
  width: 100%;
  font-size: 1rem;
}

.user:focus {
  border: 0.1rem solid #997af0;
  outline: none;
}

.pass:focus {
  border: 0.1rem solid #997af0;
  outline: none;
}

.submit-button {
  background-color: #4e0eff;
  color: white;
  padding: 1rem 2rem;
  border: none;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.4rem;
  font-size: 1rem;
  text-transform: uppercase;
}

.submit-button:hover {
  background-color: #997af0;
  transition: background-color 0.3s;
}

span {
  color: white;
  text-transform: uppercase;
}

span a {
  color: #4e0eff;
  text-decoration: none;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
};