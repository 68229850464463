.profile-content {
  width: calc(100% - 2rem);
  padding: 1rem;
  display: grid;
  grid-template-columns: 450px auto;
  margin: 1rem;
}

@media screen and (max-width: 940px) {
  .profile-content {
    grid-template-columns: auto;
    width: 100%;
    margin: 0;
  }
}

.profile-name {
  text-align: center;
  font-size: 1.75rem;
  font-weight: bold;
  margin: 0.5rem 0 0.25rem 0;
}

.profile-subtitle {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  margin: 0 0 0.5rem 0;
}

.profile-sidebar {
  margin: 1em;
}

.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid var(--primary-dark);
  overflow: hidden;
}

.show-more {
  display: inline-block;
  color: teal;
  background-color: #eee;
  padding: 0.15rem 0.5rem;
  border-radius: 4px;
  margin: 0.25rem;
  font-size: 12px;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
}

.show-more:hover {
  background-color: #ddd;
  transition: 0.3s;
}

.profile-image img {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  justify-content: center;
}

.profile-pic-button {
  display: inline-block;
  color: white;
  background-color: black;
  padding: 0.375rem 0.75rem;
  font-size: 0.5rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: 1px solid var(--primary-dark);
  cursor: pointer;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.profile-pic-button:hover {
  color: black;
  background-color: white;
  transition: 0.3s;
}

.profile-pic-button-container {
  margin-top: 0;
}

.interest-container {
  position: relative;
  display: block;
  background-color: #ffedc9;
  margin: 0.5rem;
  height: 2rem;
}

.interest {
  position: absolute;
  z-index: 10;
  height: 2rem;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.interest-bar {
  position: absolute;
  display: block;
  margin: 0;
  background-color: /* a very light orange */ #ffdc96;
  height: 2rem;
  border-radius: 8px;
}

.profile-details h3,
.section h3 {
  color: #fb5012;
  margin: 0.5rem 0;
}

.profile-details hr {
  width: 100%;
  border: 1px solid black;
  margin: 1rem 0;
}

@media screen and (max-width: 940px) {
  .profile-content {
    padding: 1rem;
  }

  .profile-image {
    width: 150px;
    height: 150px;
  }

  .profile-image img {
    width: 100%;
    height: 100%;
  }

  .profile-pic-button {
    padding: 0.375rem 0.75rem;
    font-size: 0.5rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
    text-align: center;
  }

  .profile-pic-button-container {
    margin-top: 0;
  }

  .profile-details {
    margin-top: 1rem;
  }

  .profile-details h3 {
    margin: 0.5rem 0;
  }

  .profile-details ul {
    list-style-type: none;
    padding: 0;
  }

  .profile-details ul li {
    margin-bottom: 10px;
    line-height: 1.5;
    text-align: justify;
    text-justify: inter-word;
  }
}

.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: #e5e5e5;
  height: 24px;
  outline: none;
  padding: 0;
  font-size: 12px;
  color: #333333;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
  margin: 4px;
  transition: transform 0.1s;
}

.chip:hover {
  transform: scale(1.05);
}

.chip-head {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #ffdc96;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 24px;
  color: #fff;
  height: 24px;
  font-size: 14px;
  margin-right: -4px;
}

.chip-content {
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

.personality-trait-container {
  position: relative;
  display: block;
  background-color: #ffedc9;
  margin: 0.5rem 0;
  height: 2rem;
}

.personality-trait-bar {
  position: absolute;
  display: block;
  margin: 0;
  background-color: #ffdc96;
  height: 2rem;
  border-radius: 8px;
}

.personality-trait {
  position: absolute;
  z-index: 10;
  height: 2rem;
  display: flex;
  align-items: center;
}

.personality-trait-emoji {
  margin: 0 8px;
}

.personality-trait-name {
  color: black;
  text-transform: capitalize;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: blue;
  cursor: help;
}

.chat-button-container {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.chat-button {
  display: inline-block;
  color: white;
  background-color: blue;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.5;
  font-size: 1.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: center;
  border: none;
}

.section {
  display: block;
  margin: 1rem 0.5rem;
  padding: 0.5rem 0.25rem;
}

.hobbies {
  list-style-type: square;
  padding: 0;
}

.hobby {
  margin-bottom: 10px;
  line-height: 1.5;
  text-align: justify;
  text-justify: inter-word;
}

.loading-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
}

.loading-internal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40vw;
}

@media screen and (max-width: 940px) {
  .loading-internal {
    width: 90vw;
  }
}

.loading-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0.5rem;
}

.loading-text {
  font-size: 1rem;
  margin: 1rem;
}

.loading-loader {
  margin: 1rem;
}

.loading-button {
  margin: 1rem;

  display: inline-block;
  color: white;
  background-color: blue;
  padding: 0.375rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

body {
  background-color: #f0f0f0;
}
