.chat-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    justify-content: space-between;
    background-color: #f0f0f0;
}

.chat-container button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;

}

/*
.contacts-list {
    flex: 1;
    overflow-y: auto;
}

.contact-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.contact-item img {
    border-radius: 50%;
    margin-right: 10px;
}

.contact-item:hover {
    background-color: #e0e0e0;
}
*/
