.message-display {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding: 10px 20px;
  height: 100%;
  background-color: #f0f0f0;
}

.message {
  display: block;
  padding: 8px 12px;
  border-radius: 20px;
  margin-bottom: 8px;
  max-width: 80%;
  word-wrap: break-word;
}

.sent {
  background-color: #007bff;
  color: white;
  margin-left: auto;
  text-align: right;
}

.received {
  background-color: #e1f3fb;
  color: #333;
  text-align: left;
}
