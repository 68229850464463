.match-page {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.match-card {
  width: 80%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: center;
}

.chat-button {
  background-color: #fb5012; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.chat-button:hover {
  background-color: #f44336;
}

.profile-button {
  background-color: #cccccc; /* Green */
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.profile-button:hover {
  background-color: #b3b3b3;
}

.expand-button {
  display: inline-block;
  color: blue;
  background-color: #eee;
  padding: 0.15rem 0.5rem;
  border-radius: 4px;
  margin: 0.25rem;
  font-size: 12px;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
}

.expand-button:hover {
  background-color: #ddd;
  transition: 0.3s;
}

.emoji {
  position: fixed;
  bottom: 0;
  animation: rise 2s linear;
}

@keyframes rise {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100vh);
  }
}
