/* NavBarStyles.css */

.navbar {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: black;
  color: white;
}

.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.return-button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: large;
}

.current-user-info {
  background-color: transparent;
  justify-content: center;
  align-items: center;
}

.chat-nav-avatar {
  width: 40px;
  height: 40px;
  padding: 8px;
  background-color: white;
  border-radius: 50%;
}

.chat-nav-name {
  font-size: 1.25rem;
  color: white;
}

.h2 {
  text-align: center;
}

.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
}

.hamburger {
  display: block;
}

.mobile-menu {
  display: none;
}

.icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.mobile-menu {
  flex-direction: column;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  justify-content: space-between;
  background-color: #f5f5f5;
  color: #000;
}

.active {
  top: 80px;
  display: flex;
}

.mobile-menu li {
  padding: 1.2rem 1rem;
  margin: 0 1rem;
  border-bottom: 1px solid #ccc;
}

.mobile-menu li:hover {
  background-color: var(--primary-light);
  border-radius: 8px;
  transition: 0.3s;
}

.mobile-nav {
  margin: 1rem 0;
}

.mobile-menu-bottom {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}

.social-icons {
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
}

.social-icons .icon:hover {
  transform: scale(1.5);
  transition: 0.3s;
}

.mobile-menu button {
  margin: 1rem;
  width: 90%;
}

.nav-menu {
  display: none;
}

.nav-menu li {
  display: none;
}

.nav-icons {
  display: none;
}
