@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
  --primary-dark: #5651e5; 
  --primary-light: #709dff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2.5rem;
}

.container {
  max-width: 1240px;
  margin: auto;
  padding: 0 1rem;
}

/* note img */
img {
  width: 200px;
  height: auto; 
}

@media screen and (max-width: 940px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }
} 
  